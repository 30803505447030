import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Menu from '../../components/menu/Menu';
import NavBar from '../../components/navbar/NavBar';
import Select from 'react-select';
import './TelaRelatorio.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchReport } from '../../services/api/fetchClipping';
import { fetchCategories } from '../../services/api/fetchCategories';
import RelatorioAnalitico from '../../components/Reports/AnaliticoTemplate/RelatorioAnalitico';
import ReactLoading from 'react-loading';
import { FaDownload } from 'react-icons/fa';

const TelaRelatorio = () => {
    const [selectedReport, setSelectedReport] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [reportData, setReportData] = useState(null);
    const { clientId } = useParams();
    const relatorioAnaliticoRef = useRef();
    const [isLoadingGenerate, setIsLoadingGenerate] = useState(false);
    const [isLoadingPDF, setIsLoadingPDF] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [downloadSuccessMessage, setDownloadSuccessMessage] = useState('');
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const [selectedPages, setSelectedPages] = useState([]); 

    const pageOptions = [
        { value: 'all', label: 'Todos' },
        { value: 'sumary', label: 'Sumário' },
        { value: 'qualitativeAnalysis', label: 'Análise qualitativa das matérias' },
        { value: 'textType', label: 'Notícias por tipo de texto' },
        { value: 'directIndirect', label: 'Direta/Indireta' },
        { value: 'topicWordCloud', label: 'Nuvem de Assuntos' },
        { value: 'mediaDistribution', label: 'Distribuição por mídia' },
        { value: 'occupiedSpace', label: 'Espaço ocupado na mídia' },
        { value: 'qualitativeSpace', label: 'Análise qualitativa do espaço' },
        { value: 'mediaNews', label: 'Notícias por veículo' },
        { value: 'mediaValue', label: 'Valoração por veículo' },
        { value: 'publishDate', label: 'Notícias por data de publicação' },
        { value: 'statesNews', label: 'Notícias por estado' },
        { value: 'swot', label: 'Análise SWOT' },
        { value: 'vehicles', label: 'Veículos' },
        { value: 'Final', label: 'Finalização' },
    ];

    useEffect(() => {
        if (selectedReport && selectedReport.value === 1) {
            fetchCategories(clientId).then(data => {
                const categoryOptions = data.map(category => ({
                    value: category.id,
                    label: category.name
                }));
                setCategories(categoryOptions);
            }).catch(error => {
                console.error('Erro ao carregar categorias:', error);
            });
        }
    }, [selectedReport, clientId]);

    const Reports = [{
        id: 1,
        name: "Relatório de Análise Crítica",
    }]

    const reportOptions = Reports.map(report => ({ value: report.id, label: report.name }));

    const clearMessages = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setDownloadSuccessMessage('');
    };

    const handleGenerateReport = () => {
        clearMessages(); // Limpa mensagens anteriores

        if (!startDate || !endDate || selectedPages.length === 0) {
            setErrorMessage('Por favor, selecione um intervalo de datas e ao menos uma página para gerar o relatório.');
            
            setTimeout(() => {
                clearMessages();
            }, 5000);
            return; 
        }

        setIsLoadingPDF(true); // Ativa o loading


        const categoryId = selectedCategory ? selectedCategory.value : null;

        fetchReport(clientId, startDate, endDate, categoryId)
            .then(response => {
                if (response.count === 0 || response.total === 0) {
                    setErrorMessage('Não há notícias avaliadas para o período selecionado.');
                } else {
                    setReportData(response);
                    setSuccessMessage('Relatório gerado com sucesso!');
                }
            })
            .catch(error => {
                console.error(error);
                setErrorMessage('Erro ao gerar relatório. Verifique a existência de notícias avaliadas nesse período ou tente novamente.');
            })
            .finally(() => {
                setIsLoadingPDF(false); // Desativa o loading
            });

        // Limpa mensagens após 5 segundos
        setTimeout(() => {
            clearMessages();
        }, 2000);
    };

    const handleDownloadReport = async () => {
        setIsLoadingPDF(true); // Ativa o loading no botão de download
        try {
            if (relatorioAnaliticoRef.current) {
                await relatorioAnaliticoRef.current.handlePrint();
                setDownloadSuccessMessage('Relatório exportado com sucesso!');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Erro ao fazer o download do relatório.');
        } finally {
            setIsLoadingPDF(false); // Desativa o loading
        }

        // Limpa mensagens após 5 segundos
        setTimeout(() => {
            clearMessages();
        }, 3000);
    };

    const handlePageSelection = (selected) => {
        if (selected.some(option => option.value === 'all')) {
            setSelectedPages(pageOptions.filter(option => option.value !== 'all'));
        } else {
            setSelectedPages(selected);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            maxWidth: '600px',
            padding: '10px',
            fontSize: '14px',
            borderColor: state.isFocused ? '#ccc' : '#ccc',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: '#333',
            outline: 'none',
            boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
            transition: 'border-color 0.3s, box-shadow 0.3s',
            '&:hover': {
                borderColor: '#ccc',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            maxWidth: '600px',
            backgroundColor: state.isFocused ? '#eee' : '#fff',
            color: '#333',
            padding: '10px',
            '&:active': {
                backgroundColor: '#eee',
            },
        }),
        menu: (provided) => ({
            ...provided,
            maxWidth: '600px',
            zIndex: 2,
        }),
    };

    const handleToggleMenu = (expanded) => {
        setIsMenuExpanded(expanded);
      };

    return (
        <div className={`container-tela-relatorio ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
            <div className="menu-tela-relatorio">
                <Menu onToggleMenu={handleToggleMenu} />
            </div>
            <div className="navbar-tela-relatorio">
                <NavBar />
            </div>

            <div className="main-tela-relatorio">
                <div className="row-tela-relatorio">
                    <div className="filter-tela-relatorio">
                        <div className="filter-label-relatorio">Relatório</div>
                        <Select
                            className="filter-select-relatorio"
                            styles={customStyles}
                            placeholder="Selecione um relatório"
                            value={selectedReport}
                            onChange={setSelectedReport}
                            options={reportOptions}
                        />
                    </div>
                    {selectedReport && selectedReport.value === 1 && (
                        <>
                        <div className="filter-tela-relatorio">
                            <div className="filter-label-relatorio">Paginas</div>
                                <Select
                                    className="filter-select-relatorio"
                                    styles={customStyles}
                                    placeholder="Selecione as páginas"
                                    isMulti
                                    value={selectedPages}
                                    onChange={handlePageSelection}
                                    options={pageOptions}
                                />
                            </div>


                            <div className="filter-tela-relatorio">
                                <div className="filter-label-relatorio">Categoria</div>
                                <Select
                                    className="filter-select-relatorio"
                                    styles={customStyles}
                                    placeholder="Selecione uma categoria"
                                    value={selectedCategory}
                                    onChange={setSelectedCategory}
                                    options={categories}
                                />
                            </div>
                            <div className="date-picker-tela-relatorio">
                                <div className="date-picker-label-tela-relatorio">Datas</div>
                                <div className="date-picker-header-tela-relatorio">
                                    <div className="date-picker-content-tela-relatorio">
                                        <DatePicker
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            className="selected-date-input"
                                            placeholderText='DD/MM/AAAA - DD/MM/AAAA'
                                        />
                                    </div>
                                    <img
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6cd33072230ae936f1e816d9aa1ba45453ba4d5a4f9099598c1801929589e2?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                                        alt=""
                                        className="calendar-icon-tela-relatorio"
                                        onClick={() => document.getElementsByClassName('selected-date-input')[0].click()}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="generate-report-button-container">
                    <button onClick={handleGenerateReport} disabled={isLoadingGenerate}>
                        {isLoadingGenerate ? <ReactLoading type={'spin'} color={'#ffffff'} height={30} width={30} /> : 'Gerar'}
                    </button>
                </div>

                {isLoadingPDF && (
                    <div className="loading-overlay active">
                        <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
                    </div>
                )}

                {successMessage && (
                    <div className="success-message">
                        <p>{successMessage}</p>
                    </div>
                )}

                {errorMessage && (
                    <div className="error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}

                {reportData && (
                    <>
                        <div className="preview-container">
                            <div className="preview-header">
                                <h2>Relatório gerado:</h2>
                                <button className="download-button" onClick={handleDownloadReport} disabled={isLoadingPDF}>
                                    <><FaDownload /> Download</>
                                </button>
                            </div>
                            <RelatorioAnalitico
                                ref={relatorioAnaliticoRef}
                                reportData={reportData}
                                startDate={startDate}
                                endDate={endDate}
                                selectedPages={selectedPages}
                                reportFinalImage = {
                                    reportData.images?.report_final_image
                                        ? reportData.images.report_final_image.replace('https://nxt-media.s3.amazonaws.com', 'https://clientehomologacao.contentclipping.com.br')
                                            : null
                                }
                                reportInitialimage={
                                    reportData.images?.report_cover_image
                                        ? reportData.images.report_cover_image.replace('https://nxt-media.s3.amazonaws.com', 'https://clientehomologacao.contentclipping.com.br')
                                            : 'https://clientehomologacao.contentclipping.com.br/media/reports/fundo-inicial-relatorio.png'
                                }
                                reportGrapichImage={
                                    reportData.images?.report_charts_image
                                        ? reportData.images.report_charts_image.replace('https://nxt-media.s3.amazonaws.com', 'https://clientehomologacao.contentclipping.com.br')
                                            : 'https://clientehomologacao.contentclipping.com.br/media/reports/fundo-graficos-relatorio.png'
                                }
                                reportInfoImage={
                                    reportData.images?.report_charts_image
                                        ? reportData.images.report_charts_image.replace('https://nxt-media.s3.amazonaws.com', 'https://clientehomologacao.contentclipping.com.br')
                                            : 'https://clientehomologacao.contentclipping.com.br/media/reports/fundo-informacoes.png'
                                }
                            />
                        </div>
                        {downloadSuccessMessage && (
                            <div className="success-message">
                                <p>{downloadSuccessMessage}</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default TelaRelatorio;
